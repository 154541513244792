import { forwardRef, InputHTMLAttributes } from 'react';
import { Image } from '@mui/icons-material';
import { IconButton, IconButtonProps } from 'src/components/mui-components/IconButton';
import styles from './FileInput.module.scss';

type FileInputRef = HTMLInputElement;

interface FileInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'children' | 'size'> {
  iconButtonProps: IconButtonProps;
}

export const FileInput = forwardRef<FileInputRef, FileInputProps>(
  ({ iconButtonProps, ...props }, ref) => (
    <IconButton component="label" {...iconButtonProps} title={iconButtonProps.title}>
      <Image />

      <input type="file" ref={ref} {...props} className={styles.input} />
    </IconButton>
  ),
);
