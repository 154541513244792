import React, { HTMLAttributes, useState } from 'react';
import '@reach/menu-button/styles.css';
import cx from 'classnames';
import { MenuItem, MenuLink } from '@reach/menu-button';

import { Flex } from 'src/components/ui-components/Flex';
import { Icon } from 'src/components/ui-components/Icon';
import { Dialog, DialogTitle } from 'src/components/mui-components/Dialog';
import { IconButton } from 'src/components/mui-components/IconButton';
import {
  ContextMenuItemInnerInterface,
  ContextDropDownInterface,
  ContextMenuLinkInterface,
  ContextMenuItemInterface,
  ContextMenuModalInterface,
} from './ContextMenuType';
import { ContextMenu } from './ContextMenu';
import styles from './ContextMenu.module.scss';

const ItemInner = ({ iconName, position = 'left', ...props }: ContextMenuItemInnerInterface) => (
  // Need to use span as div is not allowed inside button element
  <Flex
    gap="xxSmall"
    as="span"
    direction={position === 'right' ? 'rowReverse' : 'row'}
    horizontalAlignment={position === 'right' ? 'right' : 'left'}
  >
    {iconName && (
      <Icon
        iconName={iconName}
        aria-hidden="true"
        data-automation-id={`ContextMenuItemIcon${position}`}
      />
    )}
    <Flex.Item as="span">{props.children}</Flex.Item>
  </Flex>
);

const ItemLink = ({
  wrapText,
  iconName,
  position,
  children,
  ...restProps
}: ContextMenuLinkInterface) => (
  <MenuLink
    className={cx(styles.ContextMenu__link, {
      [styles.ContextMenu__link____wrapText]: wrapText,
    })}
    {...restProps}
  >
    <ItemInner iconName={iconName} position={position}>
      {children}
    </ItemInner>
  </MenuLink>
);

const ItemButton = ({ iconName, position, children, ...restProps }: ContextMenuItemInterface) => (
  <MenuItem as="button" className={styles.ContextMenu__link} {...restProps}>
    <ItemInner iconName={iconName} position={position}>
      {children}
    </ItemInner>
  </MenuItem>
);

const ModalMenuItem = ({
  iconName,
  position,
  size,
  headingText,
  closeText,
  headingComponent,
  childComponent,
  children,
  scrollInContent,
  ...props
}: ContextMenuModalInterface) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => setModalOpen(false);

  const childWithClose = React.cloneElement(childComponent as React.ReactElement, { handleClose });

  return (
    <>
      <MenuItem
        as="button"
        className={styles.ContextMenu__link}
        {...props}
        onSelect={() => setModalOpen(true)}
      >
        <ItemInner iconName={iconName} position={position}>
          {children}
        </ItemInner>
      </MenuItem>

      <Dialog
        fullWidth
        maxWidth={size}
        scroll={scrollInContent ? 'paper' : 'body'}
        onClose={() => setModalOpen(false)}
        open={modalOpen}
      >
        <DialogTitle
          closeText={closeText}
          headingComponent={headingComponent}
          closeModal={() => setModalOpen(false)}
        >
          {headingText}
        </DialogTitle>
        {childWithClose}
      </Dialog>
    </>
  );
};

const ItemModal = (props: ContextMenuModalInterface) => <ModalMenuItem {...props} />;

export const ContextMenuWithListItems = ({
  listItems,
  icon = <Icon iconName="moreVertical" />,
  iconButtonProps,
  ...props
}: ContextDropDownInterface) =>
  listItems && listItems.length > 0 ? (
    <ContextMenu>
      <ContextMenu.Trigger
        as={IconButton}
        title={iconButtonProps.title}
        color={iconButtonProps.color}
        {...props}
      >
        {icon}
      </ContextMenu.Trigger>
      <ContextMenu.List>
        {listItems?.map(({ key, type, visible = true, ...restProps }) => {
          switch (type) {
            case 'link':
              return visible && <ItemLink key={key} {...(restProps as ContextMenuLinkInterface)} />;
            case 'modal':
              return (
                visible && <ItemModal key={key} {...(restProps as ContextMenuModalInterface)} />
              );
            case 'button':
              return (
                visible && <ItemButton key={key} {...(restProps as ContextMenuItemInterface)} />
              );
            case 'divider':
              return (
                visible && (
                  <ContextMenu.Divider
                    key={key}
                    {...(restProps as HTMLAttributes<HTMLHRElement>)}
                  />
                )
              );
            default:
              return null;
          }
        })}
      </ContextMenu.List>
    </ContextMenu>
  ) : null;
