import { ContextMenuWithListItems } from 'src/components/ui-components/ContextMenu';
import { useGetPageActions } from 'src/apis/pagesAPI/designSevenAPI';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface IMenuButton {
  urlForTest?: string;
}

export default ({ urlForTest }: IMenuButton) => {
  const { t } = useTranslation('header');
  const { pathname } = useLocation();
  const { pageAction } = useGetPageActions(urlForTest || pathname);

  if (pageAction.length > 0)
    return (
      <ContextMenuWithListItems
        iconButtonProps={{
          title: t('ScreenHeaderActionMenu'),
          'data-automation-id': 'ScreenHeaderMoreActionButton',
        }}
        data-automation-id="ScreenHeaderMoreActionButton"
        listItems={pageAction}
      />
    );

  return null;
};
